// Polyfills
import 'promise-polyfill/src/polyfill';

// NPM packages
import lozad                                        from 'lozad';
import { disableBodyScroll, enableBodyScroll }      from 'body-scroll-lock';
import SlideUpDown                                  from 'vue-slide-up-down';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import Rellax                                       from 'rellax';
import store                                        from "./store.js";
import { mapActions, mapGetters }                   from 'vuex';
import translationFile                              from '../vue/translations.json'
import i18next                                      from 'i18next';
import VueI18Next                                   from '@panter/vue-i18next';

Swiper.use([Navigation, Pagination, Autoplay]);

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: el => {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) img[0].style.objectPosition = cropPos;
        }
        el.classList.add('loaded');
    }
});

// Import our CSS
import '../css/tailwind-base.pcss';
import '../css/app-base.pcss';
import '../css/tailwind-components.pcss';
import '../css/app-components.pcss';
import '../css/tailwind-utilities.pcss';
import '../css/app-utilities.pcss';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: axios } = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    const vClickOutside = await import( /* webpackChunkName: "v-click-outside" */ 'v-click-outside');
    
    Vue.use(VueCookies);
    Vue.use(VueI18Next);
    Vue.component('slide-up-down', SlideUpDown);
    
    await i18next.init({
        lng: document.documentElement.lang,
        fallbackLng: 'nl',
        whitelist: ['fr', 'nl'],
        resources: translationFile
    });
    const i18n = new VueI18Next(i18next);
    
    window.axios = axios;
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        store,
        i18n: i18n,
        
        directives: {
            clickOutside: vClickOutside.directive
        },
        
        components: {
            'news-grid': () => import(/* webpackChunkName: "news-grid" */ '../vue/NewsGrid.vue'),
            'projects-grid': () => import(/* webpackChunkName: "projects-grid" */ '../vue/ProjectsGrid.vue'),
            'products-grid': () => import(/* webpackChunkName: "products-grid" */ '../vue/ProductsGrid.vue'),
            'product-wishlist': () => import(/* webpackChunkName: "product-wishlist" */ '../vue/ProductWishlist.vue'),
            'frequently-asked-questions': () => import(/* webpackChunkName: "frequently-asked-questions" */ '../vue/FrequentlyAskedQuestions.vue')
        },
        
        data: {
            // Cookie consent
            showStatusBar: true,
            
            // Language switcher
            languageSwitcherOpen: false,
            
            // Search bar
            searchBarOpen: false,
            
            // Navigation
            activeSegments: [],
            activeNavElementId: null,
            isScrolling: false,
            lastScrollPos: 0,
            mobileNavIsOpen: false,
            hideMobileNav: false,
            hideMobileWebshopNav: false,
            navAsideCategoryId: '',
            
            // Other
            btnCartVisible: false,
            bottomReached: false,
            
            // FloorPlan
            roomLists: null,
            activeFloorPlanRoomIndex: null,
            
            // Search results
            searchResultsActiveTab: 'products',
            
            // Commerce
            showNewBillingAddressForm: false,
            showNewShippingAddressForm: false,
            billingAndShippingAddressesAreEqual: true,
            showItemListAtCheckout: false,
            
            // Product detail
            selectedVariantId: null,
            selectedVariant: null,
            selectedVariantAmount: 1,
            productSwiper: null,
            loadingVariant: false
        },
        
        watch: {
            selectedVariantId: function(newVal, oldVal) {
                // We need a watcher because we perform a asynchronous operation and computed values are always synchronous
                if (newVal && newVal !== oldVal) {
                    this.getVariant(newVal);
                }
            }
        },
        
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
        },
        
        mounted() {
            window.lozadObserver.observe();
    
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
            
            // Set default variant for product detail page
            if (window.defaultVariantId) {
                this.selectedVariantId = window.defaultVariantId;
                this.getVariant(window.defaultVariantId);
            }
    
            // Commerce -> Toggle new address forms when there is no address
            this.showNewBillingAddressForm = window.showNewBillingAddressForm;
            this.showNewShippingAddressForm = window.showNewShippingAddressForm;
            
            // Set CSRF token
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]')
            if (csrfToken) {
                window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content;
            } else {
                console.error('CSRF token not found.')
            }
            
            // Set siteId
            const siteId = document.head.querySelector('meta[name="siteId"]')
            if (siteId) {
                window.siteId = siteId.content;
            } else {
                console.error('siteId not found.')
            }
            
            // Set language
            const language = document.head.querySelector('meta[name="language"]')
            if (language) {
                window.language = language.content;
            } else {
                console.error('language not found.')
            }
            
            // Set active navigation highlight
            if (window.activeNavElementId) this.activeNavElementId = window.activeNavElementId;
            const strSegments = window.location.pathname;
            this.activeSegments = strSegments.split('/').slice(1); // First segment is empty
            
            // Init
            this.initHomePage();
            this.initBlockCarousel();
            this.initForms();
            this.roomLists = document.querySelectorAll('.room-list');
            
            // Load wishlist
            this.loadWishlist();
            
            // Init parallax
            new Rellax('.rellax', {
                breakpoints: [576, 768, 1024]
            });
        },
        
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
        },
        
        methods: {
            closeCartPopup() {
                let overlay = document.querySelector('.cart-popup__overlay');
                if (overlay) {
                    overlay.parentNode.removeChild(overlay);
                }
            },

            onWindowScroll() {
                const offset = this.$refs.statusBar ? this.$refs.statusBar.clientHeight : 0;
                this.isScrolling = window.pageYOffset > offset;
                
                if (!this.mobileNavIsOpen) this.hideMobileNav = (window.pageYOffset > this.lastScrollPos) && (window.pageYOffset > offset);
                
                this.lastScrollPos = window.pageYOffset;
                this.bottomReached = (window.innerHeight + window.pageYOffset) >= document.documentElement.scrollHeight - 2; // -2 for Mac quirk
            },
            
            closeStatusBar(slug) {
                VueCookies.set(`status-${ slug }`, 'hidden', '7d');
                this.showStatusBar = false;
            },
            
            toggleLanguageSwitcher() {
                this.languageSwitcherOpen = !this.languageSwitcherOpen;
            },
            
            closeLanguageSwitcher() {
                this.languageSwitcherOpen = false;
            },
            
            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navMobile) : enableBodyScroll(this.$refs.navMobile);
            },
    
            toggleMobileWebshopNav() {
                this.hideMobileWebshopNav = !this.hideMobileWebshopNav;
                this.hideMobileWebshopNav ? disableBodyScroll(this.$refs.navMobileWebshopNav) : enableBodyScroll(this.$refs.navMobileWebshopNav);
            },
            
            toggleSearchBar() {
                if (this.$refs.searchBarInput) this.$refs.searchBarInput.value = '';
                this.searchBarOpen = !this.searchBarOpen;
                if (this.searchBarOpen) this.$refs.searchBarInput.focus();
            },
            
            initProductPage() {
                if(this.productSwiper) this.productSwiper.destroy();
                const productImagesSwiperEl = document.getElementById('product-images-swiper');
                if (productImagesSwiperEl) {
                    this.productSwiper = new Swiper('#product-images-swiper', {
                        // Optional parameters -> no loop because this breaks the dynamic re-loading fo the slider for some reason
                        speed: 1000,
                        watchOverflow: true,
                        centeredSlides: true,
                        slidesPerView: 1,
                        
                        // If we need pagination
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            dynamicBullets: true,
                            dynamicMainBullets: 3,
                            clickable: true
                        },
                        
                        // Navigation arrows
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }
                    });
                }
                
                const btnCart = document.querySelector('.product #cart-btn');
                if (btnCart) {
                    // Only show when buy button is hidden
                    let observer = new IntersectionObserver((entries) => {
                        entries.forEach(entry => {
                            this.btnCartVisible = entry.isIntersecting;
                        });
                    }, { threshold: 1 });
                    observer.observe(btnCart);
                }
            },
            
            initHomePage() {
                const homeHeroSwiperEl = document.getElementById('home-hero-swiper');
                if (homeHeroSwiperEl) {
                    const imageCount = homeHeroSwiperEl.dataset.count;
                    
                    new Swiper('#home-hero-swiper', {
                        // Optional parameters
                        loop: imageCount > 1,
                        speed: 2000,
                        watchOverflow: true,
                        autoplay: {
                            delay: 5000,
                        },
                        
                        // If we need pagination
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'fraction',
                            formatFractionCurrent: number => '0' + number,
                            formatFractionTotal: number => '0' + number,
                            renderFraction: (currentClass, totalClass) => `<span class="${ currentClass }"></span><hr class="pagination-divider"></span><span class="${ totalClass }"></span>`
                        }
                    });
                }
            },
            
            initBlockCarousel() {
                const blockEl = document.querySelectorAll('.block-carousel');
                
                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        const btnNext = (block.getElementsByClassName('swiper-button-next').length) ? block.getElementsByClassName('swiper-button-next')[0] : false;
                        const btnPrev = (block.getElementsByClassName('swiper-button-prev').length) ? block.getElementsByClassName('swiper-button-prev')[0] : false;
                        
                        new Swiper(swipeEl, {
                            slidesPerView: 'auto',
                            spaceBetween: 24,
                            speed: 1000,
                            navigation: {
                                nextEl: btnNext,
                                prevEl: btnPrev,
                            }
                        });
                    }
                }
            },
            
            onMouseOverRoom(index) {
                this.activeFloorPlanRoomIndex = index;
                
                if (this.roomLists) {
                    for (const room of this.roomLists) room.classList.add('inactive');
                    this.roomLists[index - 1].classList.remove('inactive');
                }
            },
            
            onMouseOutRoom() {
                this.activeFloorPlanRoomIndex = null;
                
                if (this.roomLists) {
                    for (const room of this.roomLists) room.classList.remove('inactive');
                }
            },
            
            initForms() {
                let formFields = document.querySelectorAll('.form .fui-field');
                formFields.forEach(field => {
                    let label = field.querySelector('.fui-label');
                    let input = field.querySelector('input, textarea');
                    
                    if (label && input) {
                        input.addEventListener('focus', event => {
                            event.target.placeholder = '';
                            label.classList.add('show');
                        });
    
                        input.addEventListener('blur', event => {
                            let target = event.target;
                            target.placeholder = label.innerText;
                            label.classList.remove('show');
                        });
                    }
                });
            },
    
            deleteLineItem(id) {
                document.getElementById('remove-' + id).value = 1;
                let data = new FormData(document.getElementById('form-cart'));
                
                axios.post('.', data)
                    .then(success => {
                        location.reload();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
    
            toggleBillingAndShippingAddressesAreEqual() {
                this.billingAndShippingAddressesAreEqual = !this.billingAndShippingAddressesAreEqual;
            },
    
            getVariant(id) {
                if (id) {
                    this.loadingVariant = true;
                    axios({
                        method: 'get',
                        url: `${ window.language && window.language !== 'nl' ? '/' + window.language : '' }/api/variant/${ id }`
                    }).then(result => {
                        if (result.status === 200 && result?.data?.data) {
                            this.selectedVariant = result.data.data[0];
                            this.loadingVariant = false;
                            Vue.nextTick(() => {
                                this.initProductPage();
                            });
                        } else {
                            console.log('ERROR loading variant', result);
                        }
                    }).catch(error => {
                        console.log('ERROR loading variant', error)
                    });
                }
            },
    
            stepperRemove() {
                this.selectedVariantAmount = parseInt(this.selectedVariantAmount, 10);
                if (this.selectedVariantAmount > 1) this.selectedVariantAmount = this.selectedVariantAmount - 1;
            },
    
            stepperAdd() {
                this.selectedVariantAmount = parseInt(this.selectedVariantAmount, 10);
                if (!this.$refs.stepperAdd.classList.contains('disabled')) this.selectedVariantAmount = this.selectedVariantAmount + 1;
            },
    
            onStepperInputChanged(event, stockCount) {
                if (parseInt(event.currentTarget.value) > stockCount) this.selectedVariantAmount = stockCount;
            },
            
            ...mapActions([
                'loadWishlist',
                'addToWishlist'
            ])
        },
        
        computed: {
            ...mapGetters([
                'getWishlistCount',
                'isOnWishlist'
            ])
        }
    });
    
    return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
