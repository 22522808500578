import Vue  from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        wishlistProducts: []
    },
    
    actions: {
        loadWishlist({ commit }) {
            if (localStorage.getItem('wishlistProducts')) {
                try {
                    const ids = JSON.parse(localStorage.getItem('wishlistProducts'));
                    commit("LOAD_WISHLIST", ids);
                } catch (e) {
                    localStorage.removeItem('wishlistProducts');
                }
            }
        },
        
        addToWishlist({ commit }, id) {
            commit("ADD_TO_WISHLIST", id.toString());
        }
    },
    
    mutations: {
        LOAD_WISHLIST(state, ids) {
            state.wishlistProducts = ids;
        },
        
        ADD_TO_WISHLIST(state, id) {
            if (state.wishlistProducts.includes(id)) {
                state.wishlistProducts.splice(state.wishlistProducts.indexOf(id), 1);
            } else {
                state.wishlistProducts.push(id);
            }
    
            const parsed = JSON.stringify(state.wishlistProducts);
    
            console.log(state.wishlistProducts, parsed);
            localStorage.setItem('wishlistProducts', parsed);
        },
    },
    
    getters: {
        getWishlist (state) {
            return state.wishlistProducts;
        },
    
        getWishlistCount (state) {
            return state.wishlistProducts.length;
        },
    
        isOnWishlist: state => id => {
            return state.wishlistProducts.includes(id.toString());
        }
    }
});
